import ApolloClient, { InMemoryCache } from "apollo-boost";
import { resolvers } from "./resolvers";
import { getAuthState, handleExpiredAuth, setHeaders } from "./services/auth";
var client = null;
export var setupApolloClient = function (config) {
    client = new ApolloClient({
        cache: new InMemoryCache({
            addTypename: false,
            dataIdFromObject: function (obj) { return obj.nodeId || null; },
            fragmentMatcher: {
                match: function (_a, typeCond, context) {
                    var id = _a.id;
                    return !!context.store.get(id);
                },
            },
        }),
        clientState: {
            defaults: getAuthState(config.viewerType),
            resolvers: resolvers,
        },
        onError: handleExpiredAuth,
        request: function (operation) { return setHeaders(operation, config.viewerType); },
        uri: config.graphqlUrl,
    });
    window.__APOLLO_CLIENT__ = client;
    return client;
};
export var getApolloClient = function () {
    return client;
};
