var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
export var QUERY_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    query USER {\n        authUser @client {\n            username\n        }\n    }\n"], ["\n    query USER {\n        authUser @client {\n            username\n        }\n    }\n"])));
export var USER_SIGNIN = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    mutation USER_SIGNIN($username: String!, $password: String!) {\n        authenticate(input: { username: $username, password: $password }) {\n            jwt\n        }\n    }\n"], ["\n    mutation USER_SIGNIN($username: String!, $password: String!) {\n        authenticate(input: { username: $username, password: $password }) {\n            jwt\n        }\n    }\n"])));
export var USER_SIGNUP = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    mutation USER_SIGNUP(\n        $username: String!\n        $password: String!\n        $firstname: String!\n        $lastname: String!\n        $email: String!\n    ) {\n        registerUser(\n            input: {\n                username: $username\n                password: $password\n                firstname: $firstname\n                lastname: $lastname\n                email: $email\n            }\n        ) {\n            jwt\n        }\n    }\n"], ["\n    mutation USER_SIGNUP(\n        $username: String!\n        $password: String!\n        $firstname: String!\n        $lastname: String!\n        $email: String!\n    ) {\n        registerUser(\n            input: {\n                username: $username\n                password: $password\n                firstname: $firstname\n                lastname: $lastname\n                email: $email\n            }\n        ) {\n            jwt\n        }\n    }\n"])));
export var USER_SIGNINAS = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    mutation AuthenticateAs($username: String!, $password: String!, $role: String!) {\n        authenticate(input: { username: $username, password: $password, role: $role }) {\n            jwt\n        }\n    }\n"], ["\n    mutation AuthenticateAs($username: String!, $password: String!, $role: String!) {\n        authenticate(input: { username: $username, password: $password, role: $role }) {\n            jwt\n        }\n    }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
