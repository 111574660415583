var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _this = this;
import * as React from "react";
var useState = React.useState;
import { NavLink } from "react-router-dom";
import { FormField } from "../../components/Form/Field";
import { useAuthenticate, } from "./query";
import { Button } from "../../../shared/components/Button";
export var SigninAs = function (props) {
    var _a = __read(useState({
        username: "",
        password: "",
    }), 2), userInput = _a[0], setUserInput = _a[1];
    var _b = __read(useState([]), 2), errors = _b[0], setErrors = _b[1];
    var handleUserInputChange = function (e) {
        var _a;
        var newInput = (_a = {}, _a[e.currentTarget.name] = e.currentTarget.value, _a);
        setUserInput(function (prevUserInput) { return (__assign({}, userInput, newInput)); });
    };
    var nonAdminMutation = useAuthenticate(1);
    var handleUserSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    return [4 /*yield*/, nonAdminMutation(userInput)];
                case 1:
                    response = _a.sent();
                    if (response.errors) {
                        setErrors(response.errors.map(function (err) { return err.message; }));
                    }
                    else {
                        props.history.location.state && props.history.location.state.app
                            ? props.history.push(props.history.location.state.app)
                            : props.history.push("/");
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "w-30 fl ml5 ph2" },
        React.createElement("h1", null, "Log in"),
        React.createElement("form", { onSubmit: handleUserSubmit },
            React.createElement("div", { className: "mb3" },
                React.createElement(FormField, { label: "Username", name: "username", type: "text", value: userInput.username, onChange: handleUserInputChange })),
            React.createElement("div", { className: "mb3" },
                React.createElement(FormField, { label: "Password", name: "password", type: "password", value: userInput.password, onChange: handleUserInputChange })),
            React.createElement(Button, { type: "submit", className: "mb3" }, "Sign In"),
            errors.map(function (e, i) { return (React.createElement("div", { key: i, className: "red" }, e)); }),
            React.createElement("div", null,
                React.createElement(NavLink, { to: "/register", className: "underline" }, "Register here."),
                React.createElement("br", null)))));
};
// export const Signin = withAuthenticateUser(SigninComponent);
