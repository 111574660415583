import * as React from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import Logo from "../../../../shared/images/placeholder.png";
var style = {
    container: "flex justify-between items-center w-100 bg-white bb b--light-gray tc center mt2",
    image: "w2 pl2 self-center",
    link: "f6 f5-l link bg-animate black-80 hover-bg-lightest-blue pa3 ph4-l",
};
export var NavigationHeader = function (_a) {
    var className = _a.className;
    return (React.createElement("header", { className: cx(style.container, className), style: { height: 50 } },
        React.createElement(NavLink, { to: "/" },
            React.createElement("img", { className: style.image, src: Logo })),
        React.createElement(NavLink, { className: "center", to: "/notes" },
            React.createElement("div", { className: "fl pt2 mr2" }, "Notes"),
            React.createElement("img", { className: style.image, src: "https://image.flaticon.com/icons/svg/33/33450.svg" })),
        React.createElement(NavLink, { className: "center", to: "/edu" },
            React.createElement("div", { className: "fl pt2 mr2" }, "Edu"),
            React.createElement("img", { className: style.image, src: "https://image.flaticon.com/icons/svg/33/33490.svg" })),
        React.createElement(NavLink, { className: "center", to: "/app3" },
            React.createElement("div", { className: "fl pt2 mr2" }, "App 3"))));
};
export var NavigationSidebar = function (_a) {
    var className = _a.className;
    return (React.createElement("div", { className: "w-8 fl pl2 h-100 flex flex-column pr3 bb-0 bt-0 br bl-0 b--solid b--black-10" },
        React.createElement(NavLink, { className: "db w-100 flex-auto pv3 center mt2 bg-white", to: "/" },
            React.createElement("img", { className: style.image, src: Logo })),
        React.createElement(NavLink, { className: "db w-100 flex-auto pv3 center bg-white", to: "/notes" },
            React.createElement("img", { className: style.image, src: "https://image.flaticon.com/icons/svg/33/33450.svg" })),
        React.createElement(NavLink, { className: "db w-100 flex-auto pv3 center bg-white", to: "/edu" },
            React.createElement("img", { className: style.image, src: "https://image.flaticon.com/icons/svg/33/33490.svg" })),
        React.createElement(NavLink, { className: "db w-100 flex-auto pv3 center bg-white", to: "/app3" }, "App 3")));
};
