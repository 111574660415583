import * as React from "react";
import SimpleMDE from "react-simplemde-editor";
// tslint:disable-next-line:no-var-requires no-submodule-imports no-implicit-dependencies
require("easymde/dist/easymde.min.css");
import * as fullscreenStyle from "./style.scss";
var style = {
    fieldContainer: "mt3",
    fieldInput: "input-reset ba b--black-20 pa2 mb2 db w-100",
    fieldLabel: "db fw4 lh-copy f6",
    fieldMarkdown: fullscreenStyle.markdown,
    fieldSelect: "pa2 ba bg-transparent",
    fieldTextarea: "input-reset ba b--black-20 pa2 mb2 db w-100 h5",
};
export var FormField = function (props) {
    return (React.createElement("div", { className: style.fieldContainer },
        React.createElement("label", { className: style.fieldLabel, htmlFor: props.name }, props.label),
        props.type !== "markdown" &&
            props.type !== "textarea" &&
            props.type !== "select" && (React.createElement("input", { onChange: props.onChange, className: style.fieldInput, type: props.type || "text", name: props.name, id: props.name, value: props.value || "", required: props.required, disabled: props.disabled, readOnly: props.readOnly })),
        props.type === "textarea" && (React.createElement("textarea", { onChange: props.onChange, className: style.fieldTextarea, name: props.name, id: props.name, value: props.value || "", required: props.required, disabled: props.disabled, readOnly: props.readOnly })),
        props.type === "markdown" && (React.createElement(SimpleMDE, { onChange: props.onMarkdownChange, className: style.fieldMarkdown, id: props.name, value: props.value || "" })),
        props.type === "select" && (React.createElement("select", { onChange: props.onChange, className: style.fieldSelect, name: props.name, id: props.name, value: props.value || undefined },
            "required=",
            props.required,
            "disabled=",
            props.disabled,
            props.options.map(function (option) { return (React.createElement("option", { key: option.value, value: option.value }, option.label || option.value)); })))));
};
