var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { QUERY_USER, UserQuery } from "../components/Register/query";
import { routeWithHeader } from "../utils/routeWithNavigation";
import { Register } from "../components/Register/index";
import { SigninAs } from "../components/Register/signin";
export var AuthCheck = function (props, Component) {
    return (React.createElement(UserQuery, { query: QUERY_USER }, function (_a) {
        var data = _a.data, loading = _a.loading;
        return ((loading && React.createElement("div", null, "loading")) ||
            ((!data || !data.authUser) && (React.createElement(Redirect, { to: {
                    pathname: "/signin",
                    state: { app: props.history.location.pathname },
                } }))) || React.createElement(Component, __assign({}, props)));
    }));
};
export var AuthRoutes = function () {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/register", render: function (props) {
                return routeWithHeader(Register, props);
            } }),
        React.createElement(Route, { path: "/signin", render: function (props) {
                return routeWithHeader(SigninAs, props);
            } })));
};
