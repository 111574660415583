var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { NavigationHeader, } from "../components/Navigation/NavigationHeader";
export var routeWithHeader = function (Component, props) {
    return (React.createElement("div", null,
        React.createElement(NavigationHeader, null),
        React.createElement(Component, __assign({}, props))));
};
// export const routeWithTabBar = (Component: any) => {
//     const TabBarLayout = (props: any) => (
//         <div>
//             <Component {...props} />
//             <TabBar className={"fixed bottom-0 dn-l"} />
//         </div>
//     );
//     return TabBarLayout;
// };
// export const routeWithHeaderAndTabBar = (Component: any) => {
//     const TabBarLayout = (props: any) => (
//         <div>
//             <NavigationHeader />
//             <DesktopWrapper>
//                 <Component {...props} />
//                 <TabBar className={"fixed bottom-0 dn-l"} />
//             </DesktopWrapper>
//         </div>
//     );
//     return TabBarLayout;
// };
