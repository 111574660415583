import * as React from "react";
var heroImage = require("../shared/images/studio.jpg");
export var TryDTxHomepage = function () {
    return (React.createElement("div", null,
        React.createElement("article", { className: "dark-gray w-100 w-75-l center" },
            React.createElement("div", { className: "w-100 pa2 ph3-ns pb3-ns" },
                React.createElement("div", { className: "dt fl w-50" },
                    React.createElement("h1", { className: "f2 mv0 pv2 ph3" }, "Try DTx"),
                    React.createElement("p", { className: "f6 lh-copy measure mt2 mid-gray pv2 ph3" }, "DTx Stands for Digital Therapeutic"),
                    React.createElement("p", { className: "f6 lh-copy measure mt2 mid-gray pv2 ph3" }, "What are the things you need to know about your accute or chronic condition? How can you help youself in your recovery or to best manage your symptoms? How can you best face the challenges put in your life by your condition?"),
                    React.createElement("p", { className: "f6 lh-copy measure mt2 mid-gray pv2 ph3" }, "Digital Therapeutics puts you are the center of your own recovery, teaching you about your condition, and sharing ways to best be the agent of your own health.")),
                React.createElement("div", { className: "dt fl w-50 ph3" },
                    React.createElement("img", { src: heroImage, className: "db w-100 br2", alt: "Photo of a kitten looking menacing." }))))));
};
