var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import * as ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import { AppRoutes } from "./shared/routes";
import { AuthRoutes } from "../shared/services/routeAuth";
import { ViewerType } from "../shared/services/auth";
import { setupApolloClient } from "../shared/client";
// This import is resolved with special rules defined in our Webpack config.
// @ts-ignore
// tslint:disable-next-line:no-implicit-dependencies
import * as config from "@membright/config";
// tslint:disable-next-line
require("tachyons/css/tachyons.min.css");
import "./index.css";
var client = setupApolloClient(__assign({}, config, { viewerType: ViewerType.authenticated }));
var App = function () {
    return (React.createElement("div", null,
        React.createElement(AppRoutes, null),
        React.createElement(AuthRoutes, null)));
};
var rootElement = document.getElementById("root");
ReactDOM.render(React.createElement(ApolloProvider, { client: client },
    React.createElement(ApolloHooksProvider, { client: client },
        React.createElement(Router, null,
            React.createElement(App, null)))), rootElement);
